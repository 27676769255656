export class Contact {
  public id = '';
  public dbMainDate = "";
  public email = '';
  public firstName = '';
  public middleName = '';
  public lastName = '';
  public nameSuffix = '';
  public title = '';
  public primaryRoleId = '';
  public primaryRoleDescription = '';
  public phone = '';
  public phoneExtension = '';
  public cellPhone = '';
  public fax = '';
  public isSubscribed = true;
  public webContactNumber = '';
  public isActiveOrderCloudUser = false;
  public alternatePhoneNumber = '';
  public alternatePhoneExtension = '';
  public associatedCustomerId = '';
  public associatedPoSupplierId = '';
  public associatedApSupplierId = '';
  public marketingNotes = "";
  public associatedCustomers: { id: string, name: string }[] = [];
  public associatedPoSuppliers: { id: string, name: string }[] = [];
  public associatedApSuppliers: { id: string, name: string }[] = [];
  public associatedSalespeople: { id: string, name: string }[] = [];

  public constructor(init?: Partial<Contact>) {
    Object.assign(this, init);
  }
}
