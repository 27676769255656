import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { ContactComponent } from './contact/contact.component';
import { ContactEditComponent } from './contact/contact-edit/contact-edit.component';
import { ContactCustomersComponent } from './contact/contact-customers/contact-customers.component';
import { ContactApSuppliersComponent } from './contact/contact-ap-suppliers/contact-ap-suppliers.component';
import { ContactPoSuppliersComponent } from './contact/contact-po-suppliers/contact-po-suppliers.component';
import { ContactSalespeopleComponent } from './contact/contact-salespeople/contact-salespeople.component';
import { TokenComponent } from './token/token.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeComponent } from './home/home.component';
import { ContactDetailComponent } from './contact/contact-detail/contact-detail.component';
import { CanDeactivateGuard } from './shared/guards/can-deactivate-guard.service';
import { ContactSearchComponent } from './contact/contact-search/contact-search.component';
import { ContactMarketingNotesComponent } from './contact/contact-marketing-notes/contact-marketing-notes.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { title: 'D&W Hub' } },
  { path: 'callback', component: CallbackComponent },
  { path: 'token', component: TokenComponent, data: { title: 'Token Information' } },
  {
    path: 'contacts', canActivate: [AuthGuard], canActivateChild: [AuthGuard], component: ContactComponent,
    data: { title: 'Contact Maintenance' }, children: [
      { path: '', component: ContactSearchComponent, pathMatch: 'full' },
      { path: 'search', component: ContactSearchComponent },
      { path: 'new', component: ContactEditComponent, data: { title: 'New Contact' } },
      {
        path: ':id', component: ContactDetailComponent, children: [
          { path: '', component: ContactCustomersComponent, pathMatch: 'full' },
          { path: 'ap-suppliers', component: ContactApSuppliersComponent },
          { path: 'po-suppliers', component: ContactPoSuppliersComponent },
          { path: 'salespeople', component: ContactSalespeopleComponent },
        ]
      },
      { path: ':id/edit', component: ContactEditComponent, data: { title: 'Edit Contact' }, canDeactivate: [CanDeactivateGuard] },
      { path: ':id/editnotes', component: ContactMarketingNotesComponent, data: { title: 'Edit Contact Marketing Notes' }, canDeactivate: [CanDeactivateGuard] },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
