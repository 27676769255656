import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { Contact } from '../../contact';
import { ContactService } from '../../contact.service';

@Component({
  selector: 'app-contact-customers',
  templateUrl: './contact-customers.component.html',
  styleUrls: ['./contact-customers.component.css']
})
export class ContactCustomersComponent implements OnInit, OnDestroy {
  @ViewChild('addCust') addContactForm: NgForm;
  contact: Contact;
  private subscription: Subscription;

  constructor(
    private contactService: ContactService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.subscription = this.contactService.currentContact.subscribe(contact => this.contact = contact);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    console.log(this.addContactForm);
    console.log(`About to call service with contact id: ${this.contact.id} and customer id: ${this.addContactForm.value.customerNumber}`);
    console.log(`Pre-assignment this.contact.isActiveOrderCloudUser = ${this.contact.isActiveOrderCloudUser}`);
    this.contactService.assignCustomerToContact(
      this.contact.id, this.addContactForm.value.customerNumber.toUpperCase()
    );
    this.addContactForm.reset();
  }
}
