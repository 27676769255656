import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Contact } from '../../contact';
import { ContactService } from '../../contact.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.css']
})
export class ContactSearchComponent implements OnInit, OnDestroy {
  @ViewChild('c', { static: true }) searchForm: NgForm;
  contact: Contact;
  id: string;
  private subscription: Subscription;

  constructor(
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      if (this.id) {
        console.log(`param id value: ${this.id}`);
        this.subscription = this.contactService.currentContact.subscribe(contact => this.contact = contact);
        this.contactService.getContact(this.id);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSubmit() {
    const searchTerm = (this.searchForm.value.contactBox).trim().toLowerCase();
    // If a 10-digit number was entered, try to load by ID
    if (searchTerm.length === 10) {
      this.router.navigate(['/contacts', searchTerm]);
    } else {
      // Otherwise, attempt a search by email
      this.contactService.getContactByEmail(searchTerm)
        .subscribe(contact => {
          // Found one! Navigate to that contact via its ID
          console.log(`Found a contact with email ${searchTerm}!`);
          console.log(JSON.stringify(contact));
          this.router.navigate(['/contacts', contact.id]);
        },
        (error: any) => {
          console.log(`No contact found with email ${searchTerm}!`);
          console.log(`Returned error: ${error}`);
          if (error.status === 404) {
            this.toastr.error(`There are no contacts with email ${searchTerm}.`);
          } else {
            this.toastr.error(`An internal error occurred. Please try again later.`);
          }
        });
    }
  }
}
