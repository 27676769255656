import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
import { CustomerDocumentPreference, ApSupplierDocumentPreference, PoSupplierDocumentPreference } from './documentPreference';

@Injectable()
export class DocumentPreferenceService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  getCustomerDocumentPreference(customerId: string, contactId: string): Observable<CustomerDocumentPreference> {
    return this.http
      .get<CustomerDocumentPreference>(`${this.baseUrl}/document-preferences/customers/${customerId}/contacts/${contactId}`);
  }

  updateCustomerDocumentPreference(
    customerId: string,
    contactId: string,
    customerDocumentPreference: CustomerDocumentPreference): Observable<CustomerDocumentPreference> {
    return this.http
      .put<CustomerDocumentPreference>(
      `${this.baseUrl}/document-preferences/customers/${customerId}/contacts/${contactId}`,
      customerDocumentPreference
      );
  }

  getApSupplierDocumentPreference(apSupplierId: string, contactId: string): Observable<ApSupplierDocumentPreference> {
    return this.http
      .get<ApSupplierDocumentPreference>(`${this.baseUrl}/document-preferences/ap-suppliers/${apSupplierId}/contacts/${contactId}`);
  }

  updateApSupplierDocumentPreference(
    apSupplierId: string,
    contactId: string,
    apSupplierDocumentPreference: ApSupplierDocumentPreference): Observable<ApSupplierDocumentPreference> {
    return this.http
      .put<ApSupplierDocumentPreference>(
      `${this.baseUrl}/document-preferences/ap-suppliers/${apSupplierId}/contacts/${contactId}`,
      apSupplierDocumentPreference
      );
  }

  getPoSupplierDocumentPreference(poSupplierId: string, contactId: string): Observable<PoSupplierDocumentPreference> {
    return this.http
      .get<PoSupplierDocumentPreference>(`${this.baseUrl}/document-preferences/po-suppliers/${poSupplierId}/contacts/${contactId}`);
  }

  updatePoSupplierDocumentPreference(
    poSupplierId: string,
    contactId: string,
    poSupplierDocumentPreference: PoSupplierDocumentPreference): Observable<PoSupplierDocumentPreference> {
    return this.http
      .put<PoSupplierDocumentPreference>(
      `${this.baseUrl}/document-preferences/po-suppliers/${poSupplierId}/contacts/${contactId}`,
      poSupplierDocumentPreference
      );
  }
}
