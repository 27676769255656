import { Component } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-token',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent {

  constructor(public auth: AuthService) { }

  get accessToken() {
    return localStorage.getItem('access_token');
  }

  get idToken() {
    return localStorage.getItem('id_token');
  }

  get idTokenUserName() {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(localStorage.getItem('id_token'));
    return decodedToken.nickname;
  }

  get expiresAt() {
    return JSON.parse(localStorage.getItem('expires_at'));
  }

}
