//Angular 11
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/timer';
//import 'rxjs/add/observable/of';
//import 'rxjs/add/operator/switchMap';
//import 'rxjs/add/operator/catch';
import { catchError, switchMap, map } from 'rxjs/operators';
import { of, timer} from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Contact } from '../../contact';
import { PrimaryRole } from '../../primary-role';
import { ContactService } from '../../contact.service';
import { CanComponentDeactivate } from '../../shared/guards/can-deactivate-guard.service';
import { LoggerService } from '../../logger.service';
import { JwtTokenService } from '../../jwt.token.service';
import { NgxMaskModule } from 'ngx-mask'; //RDH 03/21/2019
//import { NG_FORM_SELECTOR_WARNING } from '../../../../node_modules/@angular/forms/src/directives';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent implements OnInit, CanComponentDeactivate {
  personForm: UntypedFormGroup;
  contact = new Contact();
  id: string;
  editMode = false;
  canExit = false;
  northAmericanPhones = false;
 
  ecommerceUserOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  emailOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
  primaryRoles: PrimaryRole[];
  formTitle = '';
  debounceTime = 500;
  associatedCustomerId: string;
  associatedPoSupplierId: string;
  associatedApSupplierId: string;
  private logMessage: string;
  private userName: string;
  private testToDemoDeploy;

  constructor(
    private fb: UntypedFormBuilder,
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private loggerService: LoggerService,
    private jwtTokenService: JwtTokenService
  ) {
    this.createForm();
    this.userName = jwtTokenService.getUserName();
    this.personForm.value.cbxNorthAmericanOption = true;
  }
  
  createForm() {
    // Reactive form setup, using Form Builder
    this.personForm = this.fb.group({
      'dBMainDate': '',
      'email': ['', [this.customEmailValidator], this.validateEmailNotTaken.bind(this)],
      'firstName': ['', Validators.required],
      'middleName': '',
      'lastName': ['', Validators.required],
      'nameSuffix': '',
      'title': '',
      'primaryRoleId': ['', Validators.required],
      'cbxNorthAmericanOption': false,
      'phone': '',
      'phoneExtension': '',
      'cellPhone': '',
      'fax': '',
      'alternatePhoneNumber': '',
      'alternatePhoneExtension': '',
      'webContactNumber': '',
      'isActiveOrderCloudUser': false,
      'isNorthAmericanPhone': false,
      'isSubscribed': true
    });
  }
  
  updateFormFields() {
    if (this.contact.id != "") {
      try {
        if (this.editMode) {
          let roleId = "";
          let noteId = "";

          if (this.primaryRoles == null) {
            this.contactService.getRoles();
            this.contactService.allRoles.subscribe(roles => {
              if (roles !== null) {
                this.primaryRoles = roles;

                for (let i = 0; i < this.primaryRoles.length; i++) {
                  if (this.contact.primaryRoleId == this.primaryRoles[i].id)
                    roleId = this.primaryRoles[i].id;
                }

                if (roleId == "") {
                  this.contact.primaryRoleId = "";
                }
              }
            })
          }
          
          //for (let i = 0; i < this.primaryRoles.length; i++) {
          //  if (this.contact.primaryRoleId == this.primaryRoles[i].id)
          //    roleId = this.primaryRoles[i].id;
          //}

          //if (roleId == "") {
          //  this.contact.primaryRoleId = "";
          //}
        }
      }
      catch(e) {
        this.contact.primaryRoleId = "";
      }
    }

    this.personForm.reset({
      dBMainDate: this.contact.dbMainDate,
      email: this.contact.email,
      firstName: this.contact.firstName,
      middleName: this.contact.middleName,
      lastName: this.contact.lastName,
      nameSuffix: this.contact.nameSuffix,
      title: this.contact.title,
      primaryRoleId: this.contact.primaryRoleId,
      phone: this.contact.phone,
      phoneExtension: this.contact.phoneExtension,
      cellPhone: this.contact.cellPhone,
      fax: this.contact.fax,
      alternatePhoneNumber: this.contact.alternatePhoneNumber,
      alternatePhoneExtension: this.contact.alternatePhoneExtension,
      webContactNumber: this.contact.webContactNumber,
      isActiveOrderCloudUser: this.contact.isActiveOrderCloudUser,
      isSubscribed: this.contact.isSubscribed,
      cbxNorthAmericanOption: !this.northAmericanPhones
    });

    if (this.route.routeConfig.path !== 'new') {
      this.editMode = true;
      
      if (this.contact.isActiveOrderCloudUser) {
        this.personForm.controls.isActiveOrderCloudUser.disable();
      }
      else {
        this.personForm.controls.isActiveOrderCloudUser.enable();
      }
    }
    else {
      this.personForm.controls.isActiveOrderCloudUser.enable();
    }
  }

  phoneChanged(e, fldName) {
    //RDH - 04/12/2019
    //      the 'g' in the regular expression means to replace ALL occurrences
    let str = e.target.value.replace(/-/g, "");
    str =     str.replace(/[* .+?^$&@{}()|[\]\\]/g, "");
    let isvalid = true;
    let extraStr = "";

    if (!isNaN(str) && str != "") {
      //Is a number value
      if (str.length == 10) {
        str = str.substr(0, 3) + "-" + str.substr(3, 3) + "-" + str.substr(6, 4);

        if (str.substr(0, 3) < 201) {
          extraStr = "  Also, North American area codes must be > 200."
          isvalid = false;
        }
      }
      else if (str.length == 11 && str.charAt(0) == 1) {
        str = str.substr(1, 3) + "-" + str.substr(4, 3) + "-" + str.substr(7, 4);

        if (str.substr(0, 3) < 201) {
          extraStr = "  Also, North American area codes must be > 200."
          isvalid = false;
        }
      }
      else {
        isvalid = false;
      }
    }
    else {
      if (str != "") {
        isvalid = false;
      }
    }
            
    if (!isvalid)
    {
      str = e.target.value;

      let html = "<br /><button type='button'>(Click here to close)</button>";
      this.toastr.info(html, "NOTE:    Please confirm that the " + fldName + "  '" + e.target.value +
        " ' is correct.  The suggested format is 999-999-9999 unless international or another format." + extraStr,
      {
        enableHtml: true,
        positionClass: "toast-top-full-width",
        timeOut: 10000
      });


      e.target.focus();
    }

    this.personForm.patchValue({ [e.target.id]: str });
  }
  
  ngOnInit() {
    if (this.route.routeConfig.path !== 'new') {
      this.editMode = true;
      this.personForm.controls.isActiveOrderCloudUser.disable();
    }
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      if (this.editMode) {
        this.formTitle = `Edit Contact ${this.id}`;
        this.contactService.getContact(this.id);
        this.contactService.currentContact.subscribe(contact => {
          if (contact !== null) {
            this.contact = contact;
          }

          this.updateFormFields();
        });
      }
      else {
        this.updateFormFields();
      }
    });

    //RDH 03/25/2019 - without this check for editmode the code continues and overwrites the formTitle if it is an edit.
    //The only switch case that will ever be executed for a new contact is 'default'.
    if (!this.editMode) {
      this.route.queryParams.subscribe((params: Params) => {
        const assignEntity = params['assign'];
        const assignId = params['id'];
        switch (assignEntity) {
          case 'customer':
            this.associatedCustomerId = assignId;
            this.formTitle = `New Contact For Customer ${assignId}`;
            break;
          case 'ap-supplier':
            this.associatedApSupplierId = assignId;
            this.formTitle = `New Contact for AP Supplier ${assignId}`;
            break;
          case 'po-supplier':
            this.associatedPoSupplierId = assignId;
            this.formTitle = `New Contact for PO Supplier ${assignId}`;
            break;
          default:
            this.formTitle = 'New Contact';
            break;
        }
      });
    }
    this.contactService.getRoles();
    this.contactService.allRoles.subscribe(roles => (this.primaryRoles = roles));
  }

  prepareSaveContact(): Contact {
    // https://angular.io/guide/reactive-forms#save-form-data
    const formModel = this.personForm.value;
    // Return new `Contact` object containing
    const saveContact: Contact = {
      id: this.contact.id,
      dbMainDate: formModel.dBMainDate as string,
      email: formModel.email as string,
      firstName: formModel.firstName as string,
      middleName: formModel.middleName as string,
      lastName: formModel.lastName as string,
      nameSuffix: formModel.nameSuffix as string,
      title: formModel.title as string,
      primaryRoleId: formModel.primaryRoleId as string,
      primaryRoleDescription: formModel.primaryRoleDescription as string,
      phone: formModel.phone as string,
      phoneExtension: formModel.phoneExtension as string,
      cellPhone: formModel.cellPhone as string,
      fax: formModel.fax as string,
      isSubscribed: formModel.isSubscribed,
      webContactNumber: formModel.webContactNumber as string,
      isActiveOrderCloudUser: formModel.isActiveOrderCloudUser,
      alternatePhoneNumber: formModel.alternatePhoneNumber as string,
      alternatePhoneExtension: formModel.alternatePhoneExtension as string,
      marketingNotes: this.contact.marketingNotes,
      associatedCustomerId: this.associatedCustomerId,
      associatedPoSupplierId: this.associatedPoSupplierId,
      associatedApSupplierId: this.associatedApSupplierId,
      associatedCustomers: this.contact.associatedCustomers,
      associatedPoSuppliers: this.contact.associatedPoSuppliers,
      associatedApSuppliers: this.contact.associatedApSuppliers,
      associatedSalespeople: this.contact.associatedSalespeople
    };

    if (formModel.isActiveOrderCloudUser != undefined) {
      saveContact.isActiveOrderCloudUser = formModel.isActiveOrderCloudUser;
    }
    else {
      saveContact.isActiveOrderCloudUser = this.contact.isActiveOrderCloudUser;
    }

    return saveContact;
  }

  onSubmit() {
    this.canExit = true;
    this.contact = this.prepareSaveContact();
    console.log(this.personForm);
    if (this.editMode) {
      this.contactService.updateContact(this.id, this.contact);
      this.router.navigate(['../'], { relativeTo: this.route });
    } else {
      this.contactService
        .addContact(this.contact)
        .subscribe(contactFromApi => {
          this.contact = contactFromApi;
          this.toastr.success(`Contact ${this.contact.id} successfully created.`);
          this.logMessage = `${this.userName} just created new Contact ${this.contact.id}.`;
          this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", this.contact.id, this.logMessage);
          // navigate to the returned contact id
          this.router.navigate(['/contacts', this.contact.id]);
        }, (error: any) => {
          console.log(`Error adding contact! Details: ${JSON.stringify(error)}`);
          this.toastr.error(error.error);
        });
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  
  onCancel() {
    this.canExit = true;
    if (this.editMode) {
      this.router.navigate(['/contacts', this.id]);
    } else {
      this.router.navigate(['/contacts']);
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.canExit && this.didFormFieldsChange()) {
      return window.confirm('Do you want to discard your changes?');
    } else {
      return true;
    }
  }
  
  didFormFieldsChange(): boolean {
    if (
      this.contact.email !== this.personForm.value.email ||
      this.contact.firstName !== this.personForm.value.firstName ||
      this.contact.lastName !== this.personForm.value.lastName ||
      this.contact.middleName !== this.personForm.value.middleName ||
      this.contact.nameSuffix !== this.personForm.value.nameSuffix ||
      this.contact.title !== this.personForm.value.title ||
      this.contact.primaryRoleId !== this.personForm.value.primaryRoleId ||
      this.contact.phone !== this.personForm.value.phone ||
      this.contact.phoneExtension !== this.personForm.value.phoneExtension ||
      this.contact.cellPhone !== this.personForm.value.cellPhone ||
      this.contact.fax !== this.personForm.value.fax ||
      this.contact.isSubscribed !== this.personForm.value.isSubscribed ||
      this.contact.webContactNumber !== this.personForm.value.webContactNumber ||
      this.contact.isActiveOrderCloudUser !== this.personForm.value.isActiveOrderCloudUser ||
      this.contact.alternatePhoneNumber !== this.personForm.value.alternatePhoneNumber ||
      this.contact.alternatePhoneExtension !== this.personForm.value.alternatePhoneExtension
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateEmailNotTaken(control: AbstractControl): Observable<ValidationErrors> {
    return timer(this.debounceTime).pipe(switchMap(() => {
      if (control.value) {
        return this.contactService
          .getContactByEmail(control.value)
          .pipe(map(result => {
            if (result.id === this.id) {
              // This is okay; it's the contact currently being edited.
              return null;
            }
            return result ? { emailTaken: true } : null;
          }))
          .pipe(catchError(err => {
            console.log(`There was an API error (${JSON.stringify(err)})`);
            return of(null);
          }));
      } else if (this.personForm.value.isActiveOrderCloudUser) {
        return of({ emailBlank: true });
      }
      else {
        return of(null);
      }
    }));
  }

  //validateEmailNotTaken(control: AbstractControl): Observable<ValidationErrors> {
  //  return Observable.timer(this.debounceTime).switchMap(() => {
  //    if (control.value) {
  //      return this.contactService
  //        .getContactByEmail(control.value)
  //        .map(result => {
  //          if (result.id === this.id) {
  //            // This is okay; it's the contact currently being edited.
  //            return null;
  //          }
  //          return result ? { emailTaken: true } : null;
  //        })
  //        .catch(err => {
  //          console.log(`There was an API error (${JSON.stringify(err)})`);
  //          return Observable.of(null);
  //        });
  //    } else {
  //      return Observable.of(null);
  //    }
  //  });
  //}

  private customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return Validators.email(control);
  }

  textChanged(e) {
    //RDH - 03/21/2019

    //Handle apostrophes
    let str = e.target.value.trim().replace("`", "'").split("'");
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].substring(1).toLowerCase();
    }
    e.target.value = str.join("'");

    //Handle spaces
    if (e.target.value.indexOf("'") == -1) {
      let str = e.target.value.split(" ");
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].substring(1).toLowerCase();
      }
      e.target.value = str.join(" ");

      e.target.value = this.fix_name(e.target.value);
    }

    this.personForm.patchValue({ [e.target.id]: e.target.value });
  }

  fix_name(name) {
    //RDH - 03/21/2019
    //change Mcdonald to McDonald, Macdonald to MacDonald
    let replacer = function (whole, prefix, word) {
      let ret = [];
      if (prefix) {
        ret.push(prefix.charAt(0).toUpperCase());
        ret.push(prefix.substr(1).toLowerCase());
      }
      ret.push(word.charAt(0).toUpperCase());
      ret.push(word.substr(1).toLowerCase());
      return ret.join('');
    }

    let pattern = /\b(ma?c)?([a-z]+)/ig;

    //RDH - 10/08/2019 - put in check for "Mack"
    if (name.toLowerCase() != "mack")
      return name.replace(pattern, replacer);
    else
      return name;
  }

  suffixChanged(e) {
    //if this is a name suffix, check for roman numerals and capitalize
    let suffixes = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
    if (suffixes.indexOf(e.target.value.toUpperCase()) > 0) {
      e.target.value = e.target.value.toUpperCase();
    }
    else {
      e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.substring(1).toLowerCase();
    }

    this.personForm.patchValue({ [e.target.id]: e.target.value });
  }
}
