import * as rg4js from 'raygun4js';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';

const VERSION_NUMBER = '1.0.0.0';

rg4js('apiKey', environment.raygun.apiKey);
rg4js('setVersion', VERSION_NUMBER);
rg4js('enableCrashReporting', true);

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any) {
    rg4js('send', {
      error: e,
    });
  }
}
