import { Component, OnInit, Input } from '@angular/core';

import { DocumentPreferenceService } from '../../../document-preference.service';
import { ApSupplierDocumentPreference } from '../../../documentPreference';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from '../../../contact.service';
import { LoggerService } from '../../../logger.service';
import { JwtTokenService } from '../../../jwt.token.service';

@Component({
  // tslint:disable-next-line
  selector: 'tr[app-contact-ap-supplier-row]',
  templateUrl: './contact-ap-supplier-row.component.html',
  styleUrls: ['./contact-ap-supplier-row.component.css']
})
export class ContactApSupplierRowComponent implements OnInit {
  @Input() apSupplierId: string;
  @Input() apSupplierName: string;
  @Input() contactId: string;
  apSupplierDocumentPreference: ApSupplierDocumentPreference;
  private logMessage: string;
  private userName: string;

  constructor(private documentPreferenceService: DocumentPreferenceService,
    private toastr: ToastrService,
    private contactService: ContactService,
    private loggerService: LoggerService,
    private jwtTokenService: JwtTokenService
  ) {
    this.userName = this.jwtTokenService.getUserName();
  }

  ngOnInit() {
    this.documentPreferenceService.getApSupplierDocumentPreference(this.apSupplierId, this.contactId)
      .subscribe(docPref => {
        this.apSupplierDocumentPreference = docPref;
      });
  }

  onChange(event) {
    this.documentPreferenceService.updateApSupplierDocumentPreference(this.apSupplierId, this.contactId, this.apSupplierDocumentPreference)
      .subscribe(contact => {
        this.toastr.success(`AP Supplier ${this.apSupplierId}, ${this.apSupplierName} document preferences updated.`);
        this.logMessage = `${this.userName} changed document preferences for AP Supplier ${this.apSupplierId} on Contact ${this.contactId}. Remittance: ${this.apSupplierDocumentPreference.remittance}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", this.contactId, this.logMessage);
      },
        (error: any) => {
          this.toastr.error('An internal error occurred. Please try again later.');
        });
  }

  onRemoveApSupplier(apSupplierId: string) {
    console.log(`About to remove AP supplier ${apSupplierId} from contact.`);
    this.contactService.removeApSupplierFromContact(this.contactId, apSupplierId);
  }

}
