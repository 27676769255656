export class CustomerDocumentPreference {
    public invoice = '';
    public estimate = '';
    public statement = '';
    public rga = '';
    public confirmation = '';

    public constructor(init?: Partial<CustomerDocumentPreference>) {
        Object.assign(this, init);
    }
}

export class PoSupplierDocumentPreference {
    public purchaseOrder = '';

    public constructor(init?: Partial<PoSupplierDocumentPreference>) {
        Object.assign(this, init);
    }
}

export class ApSupplierDocumentPreference {
    public remittance = '';

    public constructor(init?: Partial<ApSupplierDocumentPreference>) {
        Object.assign(this, init);
    }
}
