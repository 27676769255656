import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/Subscription';

import { DocumentPreferenceService } from '../../../document-preference.service';
import { CustomerDocumentPreference } from '../../../documentPreference';
import { ContactService } from '../../../contact.service';
import { Contact } from '../../../contact';
import { LoggerService } from '../../../logger.service';
import { JwtTokenService } from '../../../jwt.token.service';

@Component({
  // tslint:disable-next-line
  selector: 'tr[app-contact-customer-row]',
  templateUrl: './contact-customer-row.component.html',
  styleUrls: ['./contact-customer-row.component.css']
})
export class ContactCustomerRowComponent implements OnInit {
  @Input() customerId: string;
  @Input() customerName: string;
  @Input() contactId: string;
  customerDocumentPreference = new CustomerDocumentPreference();
  previousCustomerDocumentPreferent = new CustomerDocumentPreference();
  prevNumAttachedCustomers: number;
  private logMessage: string;
  private userName: string;

  constructor(
    private documentPreferenceService: DocumentPreferenceService,
    private toastr: ToastrService,
    private contactService: ContactService,
    private loggerService: LoggerService,
    private jwtTokenService: JwtTokenService
  ) {
    this.userName = this.jwtTokenService.getUserName();
  }

  private subscription: Subscription;
  contact: Contact;

  ngOnInit() {
    this.documentPreferenceService.getCustomerDocumentPreference(this.customerId, this.contactId)
      .subscribe(docPref => {
        this.customerDocumentPreference = docPref;
      },
      );
    this.subscription = this.contactService.currentContact.subscribe(contact => this.contact = contact);
  }

  onChange(event) {
    this.documentPreferenceService.updateCustomerDocumentPreference(this.customerId, this.contactId, this.customerDocumentPreference)
      .subscribe(contact => {
        this.toastr.success(`Customer ${this.customerId}, ${this.customerName} document preferences updated.`);
        this.logMessage = `${this.userName} changed document preferences for Customer ${this.customerId} on Contact ${this.contactId}. Invoices: ${this.customerDocumentPreference.invoice} | Estimates: ${this.customerDocumentPreference.estimate} | Statements: ${this.customerDocumentPreference.statement}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", this.contactId, this.logMessage);
      },
        (error: any) => {
          this.toastr.error('An internal error occurred. Please try again later.');
        });
  }

  onRemoveCustomer(customerId: string) {
    this.prevNumAttachedCustomers = this.contact.associatedCustomers.length;
    console.log(`About to remove customer ${customerId} from contact.`);
    console.log(`Pre-removal this.prevNumAttachedCustomers = ${this.prevNumAttachedCustomers}`)
    console.log(`Pre-removal this.contact.isActiveOrderCloudUser = ${this.contact.isActiveOrderCloudUser}`);
    console.log(`Pre-removal this.contact.associatedCustomers.length = ${this.contact.associatedCustomers.length}`);
    this.contactService.removeCustomerFromContact(this.contactId, customerId);
    console.log(`Post-removal this.prevNumAttachedCustomers = ${this.prevNumAttachedCustomers}`)
    console.log(`Post-removal this.contact.isActiveOrderCloudUser = ${this.contact.isActiveOrderCloudUser}`);
    console.log(`Post-removal this.contact.associatedCustomers.length = ${this.contact.associatedCustomers.length}`);
    console.log(`Post-logic this.prevNumAttachedCustomers = ${this.prevNumAttachedCustomers}`)
    console.log(`Post-logic this.contact.isActiveOrderCloudUser = ${this.contact.isActiveOrderCloudUser}`);
    console.log(`Post-logic this.contact.associatedCustomers.length = ${this.contact.associatedCustomers.length}`);
  }

}
