import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { Contact } from '../../contact';
import { ContactService } from '../../contact.service';

@Component({
  selector: 'app-contact-salespeople',
  templateUrl: './contact-salespeople.component.html',
  styleUrls: ['./contact-salespeople.component.css']
})
export class ContactSalespeopleComponent implements OnInit, OnDestroy {
  @ViewChild('addSalesperson') addSalespersonForm: NgForm;
  contact: Contact;
  id: string;
  private subscription: Subscription;

  constructor(
    private contactService: ContactService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.subscription = this.contactService.currentContact.subscribe(contact => this.contact = contact);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    console.log(this.addSalespersonForm);
    console.log(`About to call service with contact id: ${this.id} and salesperson id: ${this.addSalespersonForm.value.salespersonNumber}`);
    this.contactService.assignSalespersonToContact(
      this.id, this.addSalespersonForm.value.salespersonNumber.toUpperCase()
    );
    this.addSalespersonForm.reset();
  }

  onRemoveSalesperson(salespersonId: string) {
    console.log(`About to remove salesperson ${salespersonId} from contact.`);
    this.contactService.removeSalespersonFromContact(this.id, salespersonId);
  }
}
