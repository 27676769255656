import { NgxLoggerLevel } from 'ngx-logger';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=staging` then `environment.staging.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://devapi.dwdiesel.com',
  auth0: {
    // The audience is the identifier of the API configured in the Auth0 dashboard.
    audience: 'https://devapi.dwdiesel.com',
    scope: 'openid profile email',
    responseType: 'token id_token',
    domain: 'dwdiesel.auth0.com',
    clientId: 'K6nMB88Twmzl3nmo2bAjqrChkGzbsn6C',
    callbackUrl: 'https://devhub.dwdiesel.com/callback'
  },
  logging: {
    serverLoggingUrl: 'https://devapi.dwdiesel.com/log',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.DEBUG
  },
  raygun: {
    apiKey: 'lo2V23qNSSdTlzsiMVpGmA=='
  }
};
