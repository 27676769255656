import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class LoggerService {

    constructor(private logger: NGXLogger) { }

    writeHubLogToUniverse(userName: string, fileName: string, recordNumber: string, logMessage: string) {
        this.logger.debug(logMessage, userName, fileName, recordNumber);
    }
}