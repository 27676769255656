import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
//import 'rxjs/add/observable/of';
//import 'rxjs/add/observable/timer';
//import 'rxjs/add/operator/mergeMap';
import { of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as auth0 from 'auth0-js';
import { rg4js } from 'raygun4js';

@Injectable()
export class AuthService {
  // Create Auth0 web auth instance
  private auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientId,
    domain: environment.auth0.domain,
    responseType: environment.auth0.responseType,
    redirectUri: environment.auth0.callbackUrl,
    audience: environment.auth0.audience,
    scope: environment.auth0.scope,
  });
  userProfile: any;
  // Create a stream of logged in status to communicate throughout app
  //loggedIn: boolean;
  loggedIn = true;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  // Subscribe to token expiration stream
  refreshSub: Subscription;

  constructor(private router: Router) {
    // If authenticated, set local profile property,
    // update login status, and schedule auto-renewal.
    // If not authenticated and there are still items
    // in localStorage, log out.
    const lsProfile = localStorage.getItem('profile');

    if (this.tokenValid) {
      this.userProfile = JSON.parse(lsProfile);
      this.setLoggedIn(true);
      this.scheduleRenewal();
    } else if (!this.tokenValid && lsProfile) {
      this.logout(true);
    }
  }

  private setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedIn$.asObservable();
  }

  login(redirect?: string) {
    // Set redirect after login
    const destination = redirect ? redirect : this.router.url;
    localStorage.setItem('authRedirect', encodeURI(destination));
    // Auth0 authorize request
    this.auth0.authorize();
  }

  handleAuth() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.getProfile(authResult);
      } else if (err) {
        this.clearRedirect();
        this.router.navigate(['/']);
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  private getProfile(authResult) {
    // Use access token to retrieve user's profile and set session
    this.auth0.client.userInfo(authResult.accessToken, (err, profile) => {
      if (profile) {
        for (let i = 0; i < profile["https://dwdiesel.com/roles"].length; i++)
        {
          if (profile["https://dwdiesel.com/roles"][i].toLowerCase() == "marketing_security"
            || profile["https://dwdiesel.com/roles"][i].toLowerCase() == "it group") {
            localStorage.setItem('marketing_member', "true");
          }
        }
        
        profile["https://dwdiesel.com/roles"] = null;

        this.setSession(authResult, profile);
        this.redirect();
        this.clearRedirect();
      } else if (err) {
        console.warn(`Error retrieving profile: ${err.error}`);
      }
    });
  }

  private setSession(authResult, profile?) {
    // Set tokens and expiration in localStorage
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + Date.now());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // If initial login, set profile and admin information
    if (profile) {
      localStorage.setItem('profile', JSON.stringify(profile));
      this.userProfile = profile;
    }
    // Update login status in loggedIn$ stream
    this.setLoggedIn(true);
    // Schedule access token renewal
    this.scheduleRenewal();
  }

  private redirect() {
    let fullRedirect = decodeURI(localStorage.getItem('authRedirect'));
    if (fullRedirect === null || fullRedirect === undefined) {
      fullRedirect = '/';
    }
    // Preserve any query parameters and fragments from the original URL
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true
    };
    // Do the redirect
    this.router.navigateByUrl(fullRedirect, navigationExtras);
  }

  private clearRedirect() {
    // Remove redirect from localStorage
    localStorage.removeItem('authRedirect');
  }

  logout(noRedirect?: boolean) {
    // Remove all auth items from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    this.clearRedirect();
    // Reset local properties, update loggedIn$ stream
    this.userProfile = undefined;
    this.setLoggedIn(false);
    // Unschedule access token renewal
    this.unscheduleRenewal();
    // Go back to the home route
    if (noRedirect !== true) {
      this.router.navigate(['/']);
    }
  }

  get tokenValid(): boolean {
    // Check if current time is past access token's expiration
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return Date.now() < expiresAt;
  }

  renewToken() {
    this.auth0.checkSession({},
      (err, authResult) => {
        if (authResult && authResult.accessToken) {
          console.log('Renewing token now...');
          this.setSession(authResult);
        } else if (err) {
          console.warn(`Error renewing token (${err.error}: ${err.error_description}).`);
          console.log(`Full error: ${JSON.stringify(err)}`);
          // Log out without redirecting to clear auth data
          this.logout(true);
          // Log in again
          this.login();
        }
      }
    );
  }

  private scheduleRenewal() {
    // If user isn't authenticated, do nothing
    if (!this.tokenValid) { return; }
    // Unsubscribe from previous expiration observable
    this.unscheduleRenewal();
    // Create and subscribe to expiration observable
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const expiresIn$ = of(expiresAt)
      .pipe(mergeMap(expires => {
        const now = Date.now();
        // Use timer to track delay until expiration
        // to run the refresh at the proper time
        return timer(Math.max(1, expires - now));
      }));

    this.refreshSub = expiresIn$
      .subscribe(() => {
        this.renewToken();
        this.scheduleRenewal();
      });
  }

  private unscheduleRenewal() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }

}
