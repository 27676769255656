import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { Contact } from '../../contact';
import { ContactService } from '../../contact.service';

@Component({
  selector: 'app-contact-ap-suppliers',
  templateUrl: './contact-ap-suppliers.component.html',
  styleUrls: ['./contact-ap-suppliers.component.css']
})
export class ContactApSuppliersComponent implements OnInit, OnDestroy {
  @ViewChild('addSupplier', { static: true }) addSupplierForm: NgForm;
  contact: Contact;
  id: string;
  private subscription: Subscription;

  constructor(
    private contactService: ContactService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.parent.params.subscribe((params: Params) => {
      this.id = params['id'];
    });
    this.subscription = this.contactService.currentContact.subscribe(contact => this.contact = contact);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    console.log(this.addSupplierForm);
    console.log(`About to call service with contact id: ${this.id} and AP supplier id: ${this.addSupplierForm.value.apSupplierNumber}`);
    this.contactService.assignApSupplierToContact(
      this.id, this.addSupplierForm.value.apSupplierNumber.toUpperCase()
    );
    this.addSupplierForm.reset();
  }
}
