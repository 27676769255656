import { Component, OnInit, Input } from '@angular/core';

import { DocumentPreferenceService } from '../../../document-preference.service';
import { PoSupplierDocumentPreference } from '../../../documentPreference';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from '../../../contact.service';
import { LoggerService } from '../../../logger.service';
import { JwtTokenService } from '../../../jwt.token.service';

@Component({
  // tslint:disable-next-line
  selector: 'tr[app-contact-po-supplier-row]',
  templateUrl: './contact-po-supplier-row.component.html',
  styleUrls: ['./contact-po-supplier-row.component.css']
})
export class ContactPoSupplierRowComponent implements OnInit {
  @Input() poSupplierId: string;
  @Input() poSupplierName: string;
  @Input() contactId: string;
  poSupplierDocumentPreference: PoSupplierDocumentPreference;
  private logMessage: string;
  private userName: string;

  constructor(private documentPreferenceService: DocumentPreferenceService,
    private toastr: ToastrService,
    private contactService: ContactService,
    private loggerService: LoggerService,
    private jwtTokenService: JwtTokenService
  ) {
    this.userName = this.jwtTokenService.getUserName();
  }

  ngOnInit() {
    this.documentPreferenceService.getPoSupplierDocumentPreference(this.poSupplierId, this.contactId)
      .subscribe(docPref => {
        this.poSupplierDocumentPreference = docPref;
      });
  }

  onChange(event) {
    this.documentPreferenceService.updatePoSupplierDocumentPreference(this.poSupplierId, this.contactId, this.poSupplierDocumentPreference)
      .subscribe(contact => {
        this.toastr.success(`PO Supplier ${this.poSupplierId}, ${this.poSupplierName} document preferences updated.`);
        this.logMessage = `${this.userName} changed document preferences for PO Supplier ${this.poSupplierId} on Contact ${this.contactId}. Purchase Orders: ${this.poSupplierDocumentPreference.purchaseOrder}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", this.contactId, this.logMessage);
      },
        (error: any) => {
          this.toastr.error('An internal error occurred. Please try again later.');
        });
  }

  onRemovePoSupplier(poSupplierId: string) {
    console.log(`About to remove PO supplier ${poSupplierId} from contact.`);
    this.contactService.removePoSupplierFromContact(this.contactId, poSupplierId);
  }

}
