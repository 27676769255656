import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CanComponentDeactivate } from '../../shared/guards/can-deactivate-guard.service';
import { Observable } from 'rxjs/Observable';
import { JwtTokenService } from '../../jwt.token.service';
import { Contact } from '../../contact';
import { ContactService } from '../../contact.service';
import { MarketingNote } from '../../marketing-note';

@Component({
  selector: 'app-contact-marketing-notes',
  templateUrl: './contact-marketing-notes.component.html',
  styleUrls: ['./contact-marketing-notes.component.css'],
  providers: [DatePipe]
})
export class ContactMarketingNotesComponent implements OnInit, CanComponentDeactivate {
  contact = new Contact();
  id: string;
  formTitle = '';
  canExit = false;
  marketingNotes: MarketingNote[];
  private userName: string;
  VM: string;
  modified: string;

  masterSelected: boolean;
  // eslint-disable-next-line
  checklist: any;
  // eslint-disable-next-line
  checkedList: any;

  constructor(
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute,
    private jwtTokenService: JwtTokenService,
    private datePipe: DatePipe
  ) {
    this.userName = jwtTokenService.getUserName();
    this.masterSelected = true;
    this.checklist = [];
    this.VM = 'ý';
    this.modified = "";

    this.getCheckedItemList();
  }

  onCancel() {
    this.canExit = true;
    this.router.navigate(['/contacts', this.id]);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.canExit && this.didFormFieldsChange()) {
      return window.confirm('Do you want to discard your changes?');
    } else {
      return true;
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.formTitle = `Contact Marketing Notes ${this.id}`;
      this.contactService.getContact(this.id);
      this.contactService.currentContact.subscribe(contact => {
        if (contact !== null) {
          this.contact = contact;

          this.modified = this.contact.dbMainDate;
        }

        this.updateFormFields();
      })
    });
  }

  onSubmit() {
    this.getCheckedItemList();

    this.contact.marketingNotes = "";

    for (let i = 0; i < this.checkedList.length; i++) {
      if (i > 0) {
        this.contact.marketingNotes += this.VM;
      }

      this.contact.marketingNotes += this.checkedList[i].id;
    }

    const todaysDate = new Date();
    this.contact.dbMainDate = this.datePipe.transform(todaysDate, 'MM-dd-yyyy    hh:mm:ss a') + "    " + this.userName.replace(".", " ");
    
    this.contactService.updateContact(this.id, this.contact);

    this.canExit = true;
    this.router.navigate(['/contacts', this.id]);
  }

  didFormFieldsChange(): boolean {
    return false;
  }

  updateFormFields() {
    if (this.marketingNotes == null) {
      this.contactService.getMasterMarketingNotes();

      this.contactService.allNotes.subscribe(notes => {
        if (notes !== null) {
          this.marketingNotes = notes;

          const contactNotes = this.contact.marketingNotes.split(this.VM);

          for (let i = 0; i < this.marketingNotes.length; i++) {
            if (contactNotes.indexOf(this.marketingNotes[i].id) > -1) {
              this.checklist[i] = { isSelected: true, id: this.marketingNotes[i].id, description: this.marketingNotes[i].description };
            }
            else {
              this.checklist[i] = { isSelected: false, id: this.marketingNotes[i].id, description: this.marketingNotes[i].description };
              this.masterSelected = false;
            }
          }

          this.getCheckedItemList();
        }
      })
    }
  }
      
  // The master checkbox will check/ uncheck all items
  checkUncheckAll() {
    for (let i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  // Check All Checkbox Checked
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  // Get List of Checked Items
  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i]);
    }
  }
}
