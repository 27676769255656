import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { Contact } from '../../contact';
import { ContactService } from '../../contact.service';
import { NgxMaskModule } from 'ngx-mask'; //RDH 03/21/2019

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit {
  contact: Contact;
  id: string;
  isEmployee = false;
  private subscription: Subscription;
  enableNotes = false;

  constructor(
    private contactService: ContactService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (localStorage.getItem('marketing_member') == 'true') {
      this.enableNotes = true;
    }

    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      if (this.id) {
        this.subscription = this.contactService.currentContact.subscribe(contact => {
          this.contact = contact;
          // Determine if the contact is a D&W employee
          if (this.contact !== null && this.contact.email !== null) {
            const domain = this.contact.email.split('@')[1];
            this.isEmployee = (domain === 'dwdiesel.com') ? true : false;
          }
        });
        this.contactService.getContact(this.id);
      }
    });
  }

  ngOnDestory() {
    this.subscription.unsubscribe();
  }

  onEditContact() {
    this.router.navigate(['edit'], { relativeTo: this.route });
  }

  onEditContactMarketingNotes() {
    this.router.navigate(['editnotes'], { relativeTo: this.route });
  }

  // TODO: Remove this when we're done
  get diagnostic() { return JSON.stringify(this.contact, null, '  '); }

}
