import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { Contact } from './contact';
import { PrimaryRole } from './primary-role';
import { MarketingNote } from './marketing-note';
import { LoggerService } from './logger.service';
import { JwtTokenService } from './jwt.token.service';

@Injectable()
export class ContactService {
  private baseUrl = environment.apiBaseUrl;
  private contact$: BehaviorSubject<Contact> = new BehaviorSubject<Contact>(null);
  currentContact = this.contact$.asObservable();
  private primaryRoles: PrimaryRole[];
  //private primaryRoles$;
  //allRoles;
  private primaryRoles$: BehaviorSubject<PrimaryRole[]> = new BehaviorSubject<PrimaryRole[]>(null);
  allRoles = this.primaryRoles$.asObservable();
  private marketingNotes$: BehaviorSubject<MarketingNote[]> = new BehaviorSubject<MarketingNote[]>(null);
  allNotes = this.marketingNotes$.asObservable();
  private decodedToken: any;
  private userName: string;
  private logMessage: string;

  constructor(private http: HttpClient, private toastr: ToastrService, private loggerService: LoggerService, private jwtTokenService: JwtTokenService) {
    this.userName = jwtTokenService.getUserName();
  }

  ngOnInit() {
    //this.primaryRoles$ = new BehaviorSubject<PrimaryRole[]>(this.primaryRoles);
    //this.allRoles = this.primaryRoles$.asObservable();
  }

  getRoles() {
    console.log(`Getting all roles from the API...`);
    this.http.get<PrimaryRole[]>(`${this.baseUrl}/contacts/roles`)
      .subscribe(roles => this.primaryRoles$.next(roles),
        (error: any) => {
          console.log(`Returned error: ${JSON.stringify(error)}`);
          if (error.status === 404) {
            this.toastr.error(`Roles not found.`);
          } else {
            this.toastr.error(`An internal error occurred. Please try again later.`);
          }
          this.primaryRoles$.next(null);
        });
  }

  getMasterMarketingNotes() {
    console.log(`Getting all notes from the API...`);
    this.http.get<MarketingNote[]>(`${this.baseUrl}/contacts/mastermarketingnotes`)
      .subscribe(notes => this.marketingNotes$.next(notes),
        (error: any) => {
          console.log(`Returned error: ${JSON.stringify(error)}`);
          if (error.status === 404) {
            this.toastr.error(`Master marketing notes not found.`);
          } else {
            this.toastr.error(`An internal error occurred. Please try again later.`);
          }
          this.marketingNotes$.next(null);
        });
  }

  updategetMasterMarketingNotes() {
    console.log(`Getting all notes from the API...`);
    this.http.get<MarketingNote[]>(`${this.baseUrl}/contacts/mastermarketingnotes`)
      .subscribe(notes => this.marketingNotes$.next(notes),
        (error: any) => {
          console.log(`Returned error: ${JSON.stringify(error)}`);
          if (error.status === 404) {
            this.toastr.error(`Master marketing notes not found.`);
          } else {
            this.toastr.error(`An internal error occurred. Please try again later.`);
          }
          this.marketingNotes$.next(null);
        });
  }

  getContact(id: string) {
    this.http
      .get<Contact>(`${this.baseUrl}/contacts/${id}`)
      .subscribe(contactFromApi => this.contact$.next(contactFromApi),
        (error: any) => {
          console.log(`Returned error: ${JSON.stringify(error)}`);
          if (error.status === 404) {
            this.toastr.error(`Contact ID ${id} does not exist.`);
          } else {
            this.toastr.error(`An internal error occurred. Please try again later.`);
          }
          this.contact$.next(null);
        });
  }

  getContactByEmail(email: string): Observable<Contact> {
    return this.http
      .get<Contact>(`${this.baseUrl}/contacts/search/email?q=${email}`);
  }

  updateContact(id: string, updatedContact: Contact) {
    this.http
      .put<Contact>(`${this.baseUrl}/contacts/${id}`, updatedContact)
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`Contact ${id} updated.`);
        this.logMessage = `${this.userName} saved a change to Contact ${updatedContact.id}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", updatedContact.id, this.logMessage);
      },
        (error: any) => {
          if (error.status === 409) {
            this.toastr.error(
              `E-mail address ${updatedContact.email} is already assigned to an existing contact. Please use a different email address.`,
              'Duplicate Email',
              {
                timeOut: 10000
              }
            );
          } else {
            this.toastr.error(`An internal error occurred. Please try again later.`);
          }
        });
  }

  // TODO: Error handling for addContact
  addContact(newContact: Contact): Observable<Contact> {
    const contact = this.http
      .post<Contact>(`${this.baseUrl}/contacts`, newContact);
    return contact;
  }

  assignCustomerToContact(contactId: string, customerId: string) {
    this.http.post<Contact>(`${this.baseUrl}/contacts/${contactId}/customers/${customerId}`, '')
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`Customer ${customerId} added.`);
        this.logMessage = `${this.userName} added Customer ${customerId} to Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      },
        (error: any) => {
          if (error.status === 400) {
            this.toastr.error('Bad request. Please try again.');
          } else {
            this.toastr.error('An internal error occurred. Please try again later.');
          }
        });
  }

  removeCustomerFromContact(contactId: string, customerId: string) {
    this.http.delete<Contact>(`${this.baseUrl}/contacts/${contactId}/customers/${customerId}`)
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`Customer ${customerId} removed.`);
        this.logMessage = `${this.userName} removed Customer ${customerId} from Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      }, (error: any) => {
        if (error.status === 400) {
          this.toastr.error('Bad request. Please try again.');
        } else {
          this.toastr.error('An internal error occurred. Please try again later.');
        }
      });
  }

  assignApSupplierToContact(contactId: string, apSupplierId: string) {
    this.http.post<Contact>(`${this.baseUrl}/contacts/${contactId}/ap-suppliers/${apSupplierId}`, '')
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`AP Supplier ${apSupplierId} added.`);
        this.logMessage = `${this.userName} added AP Supplier ${apSupplierId} to Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      },
        (error: any) => {
          if (error.status === 400) {
            this.toastr.error('Bad request. Please try again.');
          } else {
            this.toastr.error('An internal error occurred. Please try again later.');
          }
        });
  }

  removeApSupplierFromContact(contactId: string, apSupplierId: string) {
    this.http.delete<Contact>(`${this.baseUrl}/contacts/${contactId}/ap-suppliers/${apSupplierId}`)
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`AP Supplier ${apSupplierId} removed.`);
        this.logMessage = `${this.userName} removed AP Supplier ${apSupplierId} from Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      }, (error: any) => {
        if (error.status === 400) {
          this.toastr.error('Bad request. Please try again.');
        } else {
          this.toastr.error('An internal error occurred. Please try again later.');
        }
      });
  }

  assignPoSupplierToContact(contactId: string, poSupplierId: string) {
    this.http.post<Contact>(`${this.baseUrl}/contacts/${contactId}/po-suppliers/${poSupplierId}`, '')
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`PO Supplier ${poSupplierId} added.`);
        this.logMessage = `${this.userName} added PO Supplier ${poSupplierId} to Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      },
        (error: any) => {
          if (error.status === 400) {
            this.toastr.error('Bad request. Please try again.');
          } else {
            this.toastr.error('An internal error occurred. Please try again later.');
          }
        });
  }

  removePoSupplierFromContact(contactId: string, poSupplierId: string) {
    this.http.delete<Contact>(`${this.baseUrl}/contacts/${contactId}/po-suppliers/${poSupplierId}`)
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`PO Supplier ${poSupplierId} removed.`);
        this.logMessage = `${this.userName} removed PO Supplier ${poSupplierId} from Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      }, (error: any) => {
        if (error.status === 400) {
          this.toastr.error('Bad request. Please try again.');
        } else {
          this.toastr.error('An internal error occurred. Please try again later.');
        }
      });
  }

  assignSalespersonToContact(contactId: string, salespersonId: string) {
    this.http.post<Contact>(`${this.baseUrl}/contacts/${contactId}/salespeople/${salespersonId}`, '')
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`Salesperson ${salespersonId} added.`);
        this.logMessage = `${this.userName} added salesperson ${salespersonId} to Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      },
        (error: any) => {
          if (error.status === 400) {
            this.toastr.error('Bad request. Please try again.');
          } else {
            this.toastr.error('An internal error occurred. Please try again later.');
          }
        });
  }

  removeSalespersonFromContact(contactId: string, salespersonId: string) {
    this.http.delete<Contact>(`${this.baseUrl}/contacts/${contactId}/salespeople/${salespersonId}`)
      .subscribe(contact => {
        this.contact$.next(contact);
        this.toastr.success(`Salesperson ${salespersonId} removed.`);
        this.logMessage = `${this.userName} removed salesperson ${salespersonId} from Contact ${contactId}.`;
        this.loggerService.writeHubLogToUniverse(this.userName, "CONTACTS", contactId, this.logMessage);
      }, (error: any) => {
        if (error.status === 400) {
          this.toastr.error('Bad request. Please try again.');
        } else {
          this.toastr.error('An internal error occurred. Please try again later.');
        }
      });
  }
}
