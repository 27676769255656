import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
//import 'rxjs/add/operator/filter';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/mergeMap';
import { filter, map, mergeMap } from 'rxjs/operators';
//import * as rg4js from 'raygun4js';
import { rg4js } from 'raygun4js';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) {
    auth.handleAuth();
  }

  ngOnInit() {
    // Set page title dynamically
    // See https://toddmotto.com/dynamic-page-titles-angular-2-router-events
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }))
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((event) => this.titleService.setTitle(event['title']));

    // Raygun tracking
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // // Track navigation end
        // rg4js('trackEvent', {
        //   type: 'pageView',
        //   path: event.url
        // });
      } else if (event instanceof NavigationError) {
        // Track navigation error
        rg4js('send', {
          error: event.error
        });
      }
    });

    // Add user data to Raygun
    this.auth.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        if (this.auth.userProfile) {
          console.log('Registering user in Raygun');
          // Transmit data for the currently logged-in user
          rg4js('setUser', {
            identifier: this.auth.userProfile.nickname,
            isAnonymous: false,
            email: this.auth.userProfile.email,
            firstName: this.auth.userProfile.given_name,
            fullName: this.auth.userProfile.name
          });
        }
      }
    });
  }
}
