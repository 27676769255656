import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoggerModule } from 'ngx-logger';
import { CommonModule } from '@angular/common';

import { RaygunErrorHandler } from './app.raygun.setup';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { TokenInterceptor } from './auth/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactService } from './contact.service';
import { ContactEditComponent } from './contact/contact-edit/contact-edit.component';
import { ContactComponent } from './contact/contact.component';
import { ContactDetailComponent } from './contact/contact-detail/contact-detail.component';
import { ContactCustomersComponent } from './contact/contact-customers/contact-customers.component';
import { ContactApSuppliersComponent } from './contact/contact-ap-suppliers/contact-ap-suppliers.component';
import { ContactPoSuppliersComponent } from './contact/contact-po-suppliers/contact-po-suppliers.component';
import { ContactSalespeopleComponent } from './contact/contact-salespeople/contact-salespeople.component';
import { TokenComponent } from './token/token.component';
import { CallbackComponent } from './callback/callback.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CanDeactivateGuard } from './shared/guards/can-deactivate-guard.service';
import { ContactSearchComponent } from './contact/contact-search/contact-search.component';
import { ContactCustomerRowComponent } from './contact/contact-customers/contact-customer-row/contact-customer-row.component';
import { DocumentPreferenceService } from './document-preference.service';
import { ContactApSupplierRowComponent } from './contact/contact-ap-suppliers/contact-ap-supplier-row/contact-ap-supplier-row.component';
import { ContactPoSupplierRowComponent } from './contact/contact-po-suppliers/contact-po-supplier-row/contact-po-supplier-row.component';
import { environment } from '../environments/environment';
import { LoggerService } from './logger.service';
import { JwtTokenService } from './jwt.token.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ContactMarketingNotesComponent } from './contact/contact-marketing-notes/contact-marketing-notes.component';  // RDH 03/21/2019

@NgModule({
  declarations: [
    AppComponent,
    ContactEditComponent,
    ContactComponent,
    ContactDetailComponent,
    ContactCustomersComponent,
    ContactApSuppliersComponent,
    ContactPoSuppliersComponent,
    ContactSalespeopleComponent,
    TokenComponent,
    CallbackComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    ContactSearchComponent,
    ContactCustomerRowComponent,
    ContactApSupplierRowComponent,
    ContactPoSupplierRowComponent,
    ContactMarketingNotesComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxMaskModule.forRoot(), // RDH 03/21/2019 - edit masks
    LoggerModule.forRoot(environment.logging),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler
    },
    Title,
    AuthService,
    AuthGuard,
    ContactService,
    CanDeactivateGuard,
    DocumentPreferenceService,
    LoggerService,
    JwtTokenService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
