import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtTokenService {
    private decodedToken: any;
    private userName: string;

    constructor() {
        const helper = new JwtHelperService();
        this.decodedToken = helper.decodeToken(localStorage.getItem('id_token'));
        this.userName = this.decodedToken.nickname;
    }

    getUserName(): string {
        return this.userName;
    }
}